<script setup async>
import {onMounted, ref} from 'vue'
import JwtService, {getExpireToken} from '@/common/jwt.service'
import {useStore} from 'vuex'
import {AUTH_LOGOUT} from '@/common/config'
import {MySelf} from '@/common/api.service'
import {LOGOUT, REFRESH_TOKEN} from '@/store/actions.type'
import moment from 'moment'
import {API_URL} from '@/common/config.prod'

const username = ref('')
const showMenu = ref(false)
const store = useStore()

onMounted(() => {
  const header = document.querySelector('.header--js')
  const main = document.querySelector('.page--js')
  const footer = document.querySelector('.footer--js')

  const setMainPadding = () => {
    const headerHeight = header.offsetHeight
    const footerHeight = footer.offsetHeight
    main.style.paddingTop = `${headerHeight}px`
    main.style.paddingBottom = `${footerHeight}px`
  }
  setMainPadding()
})

async function logout() {
  await store
      .dispatch(LOGOUT)
      .then(() => {
        window.location.href = AUTH_LOGOUT + '?post_logout_redirect_uri=' + encodeURIComponent(document.location.origin) + '/'
      })
}

async function activity() {
  let date = new Date()
  if (
      JwtService.getLastAction() <
      moment(date)
          .add(-180, 'm')
          .toDate()
          .getTime()
  ) {
    alert('Будет осуществлен выход из системы по причине не активности!')
    clearInterval(act)
    await logout()
  }
}

async function upToken() {
  if (getExpireToken() > 0 && moment().isAfter(moment.unix(getExpireToken()))) {
    await store.dispatch(REFRESH_TOKEN).then(async () => {}).catch(async () => {
      await logout()
    })
  }
}

if (JwtService.getToken()) {
  await MySelf.card().then(({data}) => {
    username.value = data.fullName
  }).catch(async () => {
    await logout()
    //username.value = jwtDecode(JwtService.getToken())['username']
  })

  setInterval(upToken, 1000)
}
const act = setInterval(activity, 1000)

onMounted(() => {
  JwtService.saveLastAction(new Date().getTime())
})

</script>
<template>
  <header class="header header--js">
    <div class="container">
      <div class="header__wrapper">
        <ul class="main-menu">
          <li class="menu-item">
            <a
                :href="(API_URL == 'https://test-regions.frprf.ru/api/')?'https://test-olk.frprf.ru/apps':'https://olk.frprf.ru/apps'"
                target="_blank">Иные модули</a>
          </li>
        </ul>
        <div class="header__right-box">
          <div class="user-profile user-profile--js">
            <div class="user-profile__user user-profile__user--js" @click="showMenu = !showMenu">
              <span class="user-profile__user-name">{{ username }}</span>
              <span class="user-profile__chevron-icon user-profile__chevron-icon--js"></span>
            </div>
            <div class="user-profile__window user-profile__window--js" :class="{active:showMenu}">
              <div class="user-profile__window-bottom user-profile__link--js">
                <a href="#" @click="logout()">Выйти</a>
              </div>
            </div>
          </div>
          <a class="header__help-icon" href="#"></a>
        </div>
      </div>
    </div>
  </header>
</template>