const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const EXPIRE_TOKEN = "expire_token";
const LAST_ACTION_TIME = "last_action";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const saveExpireToken = token => {
  window.localStorage.setItem(EXPIRE_TOKEN, token);
};

export const getExpireToken = () => {
  return window.localStorage.getItem(EXPIRE_TOKEN);
};

export const saveRefreshToken = token => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getLastAction = () => {
  return window.localStorage.getItem(LAST_ACTION_TIME);
};

export const saveLastAction = time => {
  window.localStorage.setItem(LAST_ACTION_TIME, time);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getLastAction,
  saveLastAction,
  saveRefreshToken,
  getRefreshToken,
  saveExpireToken,
  getExpireToken
};
