<script setup>

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li><a href="/regions">Модуль регионов</a></li>
              <li>Список регионов</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper content__wrapper--centered">
            <div class="error-container">
              <div class="error-container__img error-container__img--403"></div>
              <div class="error-container__description">
                <div class="error-title">Страница недоступна</div>
                <div class="error-subtitle">Запрашиваемая страница недоступна.</div>
                <div class="error-subtitle">Обратитесь к вашему системному администратору или вернитесь на <a href="/regions" class="link link--error">главную страницу</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>