export const API_URL = "__API_URL__";
export const AUTH_URL = "__AUTH_URL__";
export const AUTH_TOKEN_URL = "__AUTH_TOKEN_URL__";
export const AUTH_LOGOUT = "__AUTH_LOGOUT__";
export const AUTH_PROFILE = "__AUTH_PROFILE__";
export const GATEWAY_URL = "__GATEWAY_URL__";
export const CLIENT_ID = "__CLIENT_ID__";
export const CLIENT_SECRET = "__CLIENT_SECRET__";
export const CLIENT_URI = "__CLIENT_URI__"
export const OLK_URL = '__OLK_URL__'
export const IS_DEV_MODE = false;
