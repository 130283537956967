<script setup>
import MainFooter from "@/components/partial/MainFooter.vue";
import {ref, computed, watch, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {Regulations, Regions, Dictionary, MySelf} from "@/common/api.service";

const route = useRoute();
const router = useRouter();

const listNpa = ref([]);
const listFilter = ref({
  region: "",
  status: (parseInt(route.query.status) > 0)?parseInt(route.query.status):""
});
const listSelected = ref({
  region: "",
  status: (parseInt(route.query.status) > 0)?parseInt(route.query.status):""
});

const regions = ref([]);
const statutes = ref([]);
const isRegionalFundEmployee = ref(false);
const selectedRegion = computed(() => {
  return route.params.region || "";
});
const permissions = ref([])

watch(selectedRegion, (newRegion) => {
  if (!isRegionalFundEmployee.value) {
    listSelected.value.region = newRegion;
  }
});

async function fetchListNpa() {
  if (isRegionalFundEmployee.value){
    await Regulations.listNpaQuery({selectedRegion})
        .then(({data}) => {
          listNpa.value = data;
        })
        .catch(async () => {
          await router.push({name: "Error"});
        });
  }else {
    await Regulations.listNpaQuery(selectedRegion.value)
        .then(({data}) => {
          listNpa.value = data;
        })
        .catch(async () => {
          await router.push({name: "Error"});
        });
  }
}

await Regions.permissions()
    .then(async ({data}) => {
      permissions.value = data
    }).catch(async () => {
      await router.push({name: 'Error'})
    })

const filteredListNpa = computed(() => {
  let r = listNpa.value;
  if (isRegionalFundEmployee.value) {
    r = r.filter(v => v.regionId === listSelected.value.region);
    if (listFilter.value.status !== "") {
      r = r.filter(v => v.npaStatus === listFilter.value.status);
    }
  } else {
    if (listFilter.value.region !== "" && listFilter.value.region !== null) {
      r = r.filter(v => v.regionId === listFilter.value.region);
    }
    if (listFilter.value.status !== "" && listFilter.value.status !== null) {
      r = r.filter(v => v.npaStatus === listFilter.value.status);
    }
  }
  return r;
});

const regionList = computed(() => {
  let r = [];
  regions.value.forEach(function (region) {
    r.push({value: region.id, label: region.title});
  });
  return r;
});

const statutesList = computed(() => {
  let r = [];
  statutes.value.forEach(function (status) {
    r.push({value: status.value, label: status.title});
  });
  return r;
});

await Regions.list()
    .then(({data}) => {
      regions.value = data;
    }).catch(() => {
    });

await Dictionary.regulationsStatuses()
    .then(({data}) => {
      statutes.value = data;
    }).catch(() => {
    });

function moveFilter() {
  listFilter.value = JSON.parse(JSON.stringify(listSelected.value));

  if (listFilter.value.status == null){
     listFilter.value.status = '';
  }

  if (parseInt(listFilter.value.status) > 0) {
    router.push({name: 'ListNpa', params: {region: listFilter.value.region}, query: {status: listFilter.value.status}})
  }else{
    router.push({name: 'ListNpa', params: {region: listFilter.value.region}})
  }
}

function clearFilter() {
  if (isRegionalFundEmployee.value) {
    listFilter.value.status = "";
  } else {
    listFilter.value = {
      region: "",
      status: ""
    };
    listSelected.value = {
      region: "",
      status: ""
    };

    router.push({name: 'ListNpa', params: {region: ''}})

  }
}

function permission(permission) {
  if (!permissions.value.find((p) => p === permission)) {
    console.warn("no permission", permission)
  }
  return permissions.value.find((p) => p === permission)
}

onMounted(async () => {
  await fetchListNpa();
  await MySelf.card().then(async ({data}) => {
    isRegionalFundEmployee.value = data.isRegionalFundEmployee;
  });

  if (selectedRegion.value) {
    listSelected.value.region = selectedRegion.value;
    moveFilter();
  }

});
</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                </router-link>
              </li>
              <li>Реестр НПА по СПИК</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__title">
              <h1 class="title title-h1">Реестр НПА по СПИК</h1>
              <template v-if="!isRegionalFundEmployee">
                <router-link
                    v-if="permission('/regions/npa:create')"
                   :to="{ name: 'AddCardNpa', query: { region: (listSelected.region) ? listSelected.region : '' } }" class="button">
                  Добавить НПА
                </router-link>
              </template>
              <template v-else></template>
            </div>
            <form action="#" class="region-list__form" @submit.prevent>
              <div class="region-list__form-body">
                <Multiselect class="field field--select field--m-width-300 margin-0"
                             v-model="listSelected.region" mode="single" :options="regionList"
                             :show-no-results="false" :searchable="true" placeholder="Наименование региона"
                             :no-options-text="'Укажите регион'"
                             :class="{ 'field--viewing': isRegionalFundEmployee }"
                             :disabled="isRegionalFundEmployee"/>

                <Multiselect class="field field--select field--m-width-265 margin-0"
                             v-model="listSelected.status" mode="single" :options="statutesList"
                             :show-no-results="false" :searchable="true" placeholder="Статус НПА"
                             :no-options-text="'Укажите статус'"/>
              </div>
              <div class="buttons-block">
                <button type="reset" class="button button--clear" @click="clearFilter">Очистить
                  фильтры
                </button>
                <button type="button" class="button" @click="moveFilter">Поиск</button>
              </div>
            </form>
            <div class="table-responsive">
              <table class="table region-list region-list--npa">
                <thead>
                <tr>
                  <th>Название региона</th>
                  <th>Тип</th>
                  <th>Название</th>
                  <th>Статус</th>
                  <th>Ставка налога, %</th>
                  <th>Комментарий</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in filteredListNpa" :key="item.id">
                  <td class="padding-right-130">
                    <router-link :to="{ name: 'CardRegions', params: { id: item.regionId } }" class="link link--big">
                      {{ item.regionTitle }}
                    </router-link>
                  </td>
                  <td class="max-width-400">{{ item.npaTypeText }}</td>
                  <td class="max-width-400">
                    <router-link :to="{
                                                name: 'CardNpa',
                                                params: {
                                                    region: item.regionId,
                                                    title: item.id
                                                }
                                            }" class="link">
                      {{ item.npaTitle }}
                    </router-link>
                  </td>
                  <td>{{ item.npaStatusText }}</td>
                  <td>{{ item.taxRate }}</td>
                  <td>{{ item.comment }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>
