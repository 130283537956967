<script setup>
import MainFooter from '@/components/partial/MainFooter.vue'
import {ref, defineProps, computed, onMounted} from 'vue'
import Shared from '@/common/shared'
import {Regions, Dictionary, Regulations, MySelf} from '@/common/api.service'
import {useRouter} from 'vue-router'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'

const router = useRouter()
const toast = useToast()

const props = defineProps({
  selectedRegion: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})

const regions = ref([])
const statutes = ref([])
const typesNpa = ref([])
const regionSelected = ref(props.selectedRegion)
const regulationId = ref(props.title)
const npaData = ref({})
const isRegionalFundEmployee = ref(false)
const permissions = ref([])
const itemFields = ref([])

const setRef = (el) => {
  itemFields.value.push(el)
}

await Regulations.getNpa(regionSelected.value, regulationId.value)
    .then(({data}) => {
      npaData.value = data
    })
    .catch(async () => {
    })

await Regions.permissions()
    .then(async ({data}) => {
      permissions.value = data
    }).catch(async () => {
      await router.push({name: 'Error'})
    })

function permission(permission) {
  if (!permissions.value.find((p) => p === permission)) {
    console.warn("no permission", permission)
  }
  return permissions.value.find((p) => p === permission)
}

await Regions.list()
    .then(({data}) => {
      regions.value = data
    })
    .catch(() => {
    })

await Dictionary.regulationsStatuses()
    .then(({data}) => {
      statutes.value = data
    })
    .catch(() => {
    })

await Dictionary.regulationsTypes()
    .then(({data}) => {
      typesNpa.value = data
    })
    .catch(() => {
    })

const regionList = computed(() => {
  let r = []
  if (Array.isArray(regions.value)) {
    regions.value.forEach(function (region) {
      r.push({value: region.id, label: region.title})
    })
  }
  return r
})

const statutesList = computed(() => {
  let r = []
  if (Array.isArray(statutes.value)) {
    statutes.value.forEach(function (status) {
      r.push({value: status.value, label: status.title})
    })
  }
  return r
})

const typesNpaList = computed(() => {
  let r = []
  if (Array.isArray(typesNpa.value)) {
    typesNpa.value.forEach(function (typeNpa) {
      r.push({value: typeNpa.value, label: typeNpa.title})
    })
  }
  return r
})

async function save() {
  let isValid = true

  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (isValid) {
    if (!npaData.value.id) {
      await postNew()
    } else {
      await post()
    }
  } else {
    toast.error('НПА не сохранена, проверьте обязательные поля', {
      timeout: 5000
    })
  }
}

async function post() {
  Regulations.updateNpa(regionSelected.value, regulationId.value, npaData.value)
      .then(async () => {
        if (regionSelected.value) {
          await router.push({name: 'ListNpa', params: {region: regionSelected.value}})
        }
      })
      .catch(() => {
      })
}

async function postNew() {
  await Regulations.createNpa(regionSelected.value, npaData.value)
      .then(() => {
        return router.push({name: 'ListNpa', params: {region: regionSelected.value}})
      })
      .catch(() => {
      })
}

async function showDelDocument() {
  Shared.showModal('#del-document')
}

async function closeDelDocument() {
  Shared.closeModal('#del-document')
}

async function showDelModal() {
  Shared.showModal('#close-document')
}

async function closeDelModal() {
  Shared.closeModal('#close-document')
  await router.push({name: 'ListNpa', params: {regionSelected}})
}

async function closeEditNpa() {
  if (regionSelected.value) {
    router.push({name: 'ListNpa', params: {region: regionSelected.value}})
  }
}

async function removeNpa() {
  Regulations.removeNpa(regionSelected.value, regulationId.value, npaData.value)
      .then(async () => {
        await closeDelDocument()
        await showDelModal()
      })
      .catch(() => {
      })
}

// async function reset() {
//   if (!npaData.value.id && regionSelected.value) {
//     await router.push({ name: 'ListNpa', params: { region: regionSelected.value } });
//   } else {
//     await Regulations.getNpa(regionSelected.value, regulationId.value)
//         .then(({data}) => {
//           npaData.value = data
//         })
//         .catch(async () => {
//         })
//   }
// }

onMounted(async () => {
  await MySelf.card().then(async ({data}) => {
    isRegionalFundEmployee.value = data.isRegionalFundEmployee
  })
})
</script>


<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ListNpa', params: { region: regionSelected } }">
                  Реестр НПА по СПИК
                </router-link>
              </li>
              <li v-if="npaData.id">{{ npaData.title }}</li>
              <li v-else>Добавление нового НПА</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract" v-if="npaData.id">{{ npaData.title }}</h1>
                <h1 class="title title-h1--contract" v-else>Добавление нового НПА</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <ValidateInput
                        label-class="title-field"
                        :name="'Регион'"
                        :ref="setRef"
                        required
                        canSetNull
                        v-model="regionSelected">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect class="field field--select margin-0"
                                     name="region"
                                     v-model="regionSelected"
                                     mode="single"
                                     :options="regionList"
                                     :show-no-results="false" :searchable="true"
                                     placeholder="Наименование региона" :no-options-text="'Укажите регион'"
                                     :class="{ 'field--viewing': isRegionalFundEmployee, unvalidate: validationFailed }"
                                     :disabled="(npaData.id)?true:false"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field"
                        :name="'Тип'"
                        :ref="setRef"
                        required
                        v-model="npaData.regulationsType">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect class="field field--select margin-0" name="type-NPA"
                                     v-model="npaData.regulationsType" mode="single" :options="typesNpaList"
                                     :show-no-results="false" :searchable="true"
                                     placeholder="Выберите из списка" :no-options-text="'Укажите тип'"
                                     :class="{ 'field--viewing': isRegionalFundEmployee, unvalidate: validationFailed  }"
                                     :disabled="isRegionalFundEmployee"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        label-class="title-field"
                        :name="'Статус'"
                        :ref="setRef"
                        required
                        canSetNull
                        v-model="npaData.regulationsStatus">
                      <template v-slot:field="{validationFailed}">
                        <Multiselect class="field field--select margin-0" name="status-NPA"
                                     v-model="npaData.regulationsStatus" mode="single"
                                     :options="statutesList" :show-no-results="false" :searchable="true"
                                     placeholder="Статус НПА" :no-options-text="'Укажите статус'"
                                     :class="{ 'field--viewing': isRegionalFundEmployee, unvalidate: validationFailed }"
                                     :disabled="isRegionalFundEmployee"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Название'"
                        placeholder="Введите название НПА"
                        :ref="setRef"
                        required
                        textarea
                        maxlength="255"
                        :disabled="isRegionalFundEmployee"
                        :class="{ 'field--viewing': isRegionalFundEmployee }"
                        v-model="npaData.title">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Размер налоговой ставки для федеральных СПИК, %'"
                        number
                        placeholder="Введите размер налоговой ставки в процентах"
                        :float-format="2"
                        :ref="setRef"
                        maxlength="7"
                        v-model="npaData.federalRate">
                    </ValidateInput>
                    <ValidateInput
                        :name="'Размер налоговой ставки для региональных СПИК, %'"
                        number
                        placeholder="Введите размер налоговой ставки в процентах"
                        :float-format="2"
                        :ref="setRef"
                        maxlength="7"
                        v-model="npaData.regionalRate">
                    </ValidateInput>
                  </div>
                  <div class="contract__item">
                    <ValidateInput
                        label-class="title-field"
                        :name="'Дата проверки'"
                        :ref="setRef"
                        required
                        v-model="npaData.verificationDate">
                      <template v-slot:field="{validationFailed}">
                        <VueDatePicker name="transfer-date" placeholder="00.00.0000"
                                       model-type="yyyy-MM-dd" class="field field--calendar"
                                       v-model="npaData.verificationDate"
                                       :class="{ 'field--viewing': isRegionalFundEmployee, unvalidate: validationFailed }"
                                       :disabled="isRegionalFundEmployee"/>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Комментарий'"
                        placeholder="Введите комментарий"
                        :ref="setRef"
                        required
                        textarea
                        maxlength="2000"
                        :disabled="isRegionalFundEmployee"
                        :class="{ 'field--viewing': isRegionalFundEmployee }"
                        v-model="npaData.comment">
                    </ValidateInput>
                    <div class="entry-info marginTop80" v-if="npaData.id">
                      <div class="entry-info__item">
                        Дата создания записи:<span>{{
                          new
                          Date(npaData.createdAt).toLocaleString()
                        }}</span>
                      </div>
                      <div class="entry-info__item">
                        Создание записи, кем:<span>{{ npaData.creator.firstName }} {{
                          npaData.creator.lastName
                        }}</span>
                      </div>
                      <div class="entry-info__item">
                        Дата изменения записи:<span>{{
                          new
                          Date(npaData.modifiedAt).toLocaleString()
                        }}</span>
                      </div>
                      <div class="entry-info__item">
                        Изменение записи, кем:<span>{{ npaData.modifier.firstName }} {{
                          npaData.modifier.lastName
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <button type="button" class="button button--delete modal-open--js"
                          data-modal="del-document" @click="showDelDocument"
                          :class="{ 'hidden': isRegionalFundEmployee }"
                          v-if="npaData.id && permission('/regions/npa:delete')">Удалить
                  </button>
<!--                  <button type="button" class="button button&#45;&#45;clear" @click="reset"-->
<!--                          :class="{ 'hidden': isRegionalFundEmployee }" v-if="permission('/regions/npa:update')">Отмена-->
<!--                    изменений-->
<!--                  </button>-->
                  <button type="button" class="button" @click="save"
                          :class="{ 'hidden': isRegionalFundEmployee }" v-if="permission('/regions/npa:update') ||  permission('/regions/npa:create')">
                    сохранить
                  </button>
                  <button type="button" class="button button--clear"
                          @click="closeEditNpa">Отмена изменений
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup">
          <div class="modal-title">Удаление документа</div>
          <div class="modal-body">Вы действительно хотите удалить НПА?</div>
          <div class="modal-bottom button-dialog-center">
            <div class="buttons-block">
              <button type="button" class="button button--clear close-modal--js"
                      @click="closeDelDocument">Закрыть
              </button>
              <button type="submit" class="button button--delete sub-modal-open--js"
                      data-modal="close-document" @click="removeNpa">Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Документ удален</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button close-modal--js" @click="closeDelModal">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>