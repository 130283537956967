<script setup>

import Shared from '@/common/shared'
import {FileDocument, Funds} from '@/common/api.service'
import {toRefs, defineProps, ref, onMounted} from 'vue'
import ValidateInput from '@/components/validate/input.vue'
import {useToast} from 'vue-toastification'
import {useDropzone} from 'vue3-dropzone'

const toast = useToast()
const props = defineProps({
  ceo: {},
  item: {},
  ceoPhoto: {
    type: Function,
  }
})
const {ceo, item} = toRefs(props)
const itemFields = ref([])
const setRef = (el) => {
  itemFields.value.push(el)
}
const docFile = ref()
const fileData = ref({})
const {getRootProps, getInputProps} = useDropzone({onDrop, accept: [".png", ".jpg", ".jpeg", ".gif", ".bmp"]})
const dragStarted = ref(false)
const dbl = ref(0)

function onDrop(acceptFiles) {
  docFile.value = acceptFiles[0]
  dragStarted.value = false
}

const dragEnter = () => {
  dragStarted.value = true
}
const dragEnd = () => {
  console.log('dragEnd')
}

async function onAddDocFile(e) {
  let files = e.target.files
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    docFile.value = file
  }
}

async function save() {
  let isValid = true
  itemFields.value.forEach((f) => {
    f.validate()
    if (f.validationFailed) {
      isValid = false
    }
  })

  if (isValid) {
    await post()
    dbl.value++
    await close()
  } else {
    dbl.value++
    toast.error('Поле ФИО является обязательным для заполнения', {
      timeout: 5000
    })
  }
}

async function close() {
  Shared.closeModal('#document-form')
  Shared.closeModal('#del-document-form')
  Shared.closeModal('#close-document')
  ceo.value.modal = false
}

async function post() {
  Funds.createCEO(item.value.fund.id, ceo.value)
      .then(async () => {
        await Funds.item(item.value.fund.id)
            .then(({data}) => {
              item.value.fund = data
            }).catch(() => {
            })
      }).catch(() => {
  })
  if (ceo.value.fileId && !fileData.value.name) {
    await Funds.deleteCEOPhoto(item.value.fund.id)
    await Funds.item(item.value.fund.id)
        .then(({data}) => {
          item.value.fund = data
        }).catch(() => {
        })
    props.ceoPhoto()
  }
  if (docFile.value && docFile.value.name) {
    let formData = new FormData()
    formData.append('data', docFile.value)
    formData.append('name', docFile.value.name)
    formData.append('mimeType', docFile.value.type)
    await FileDocument.fileTemp(formData).then(({data}) => {
      Funds.createCEOPhoto(item.value.fund.id, {
        'temporaryFileId': data.id,
        'width': 0,
        'height': 0
      })
          .then(async () => {
            await Funds.item(item.value.fund.id)
                .then(({data}) => {
                  item.value.fund = data
                }).catch(() => {
                })
            props.ceoPhoto()
          }).catch(() => {
      })
    }).catch(() => {

    })
  }
}

async function remove() {
  await Funds.deleteCEO(item.value.fund.id)
  await Funds.item(item.value.fund.id)
      .then(({data}) => {
        item.value.fund = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document-form')
  Shared.showModal('#close-document')
}

async function showRemove() {
  Shared.closeModal('#document-form')
  Shared.showModal('#del-document-form')
}

function removeDoc() {
  fileData.value.name = ''
}

if (ceo.value.fileId) {
  await FileDocument.fileMeta(ceo.value.fileId)
      .then(({data}) => {
        fileData.value = data
      }).catch(() => {
      })
}

onMounted(() => {
  Shared.showModal('#document-form')
  if (ceo.value.toDel) {
    showRemove()
  }
})

</script>

<template>
  <div class="modal-overlay" id="document-form" @dragenter="dragEnter" @dragleave="dragEnd">
    <div class="popup">
      <div class="modal-title title-h3">Директор РФРП</div>
      <div class="modal-body">
        <ValidateInput
            :name="'ФИО'"
            placeholder="Введите ФИО"
            :ref="setRef"
            required
            v-model="ceo.fullName">
        </ValidateInput>
        <ValidateInput
            label-class="title-field title-field--relative"
            :name="'Дата вступления в должность'"
            :ref="setRef"
            v-model="ceo.hiringDate">
          <template v-slot:field="{validationFailed}">
            <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                           :class="{unvalidate: validationFailed}"
                           v-model="ceo.hiringDate"/>
          </template>
        </ValidateInput>

        <label for="file-doc" class="title-field title-field--relative">Файл фото
          <div class="title-field__doc" v-if="fileData.name">
            <span style="margin-right: 5px">{{ fileData.name }}</span>
            <button type="button" class="button-del" style="display: inline" @click="removeDoc()"></button>
          </div>
          <div v-else
               class="label-add-file transparent"
               :class="{
                'drag-started':dragStarted,
               }"
               v-bind="getRootProps()">
            <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
            <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл
                  (максимум 25Мб)</span>
            <span class="field field--file" v-else>{{ docFile.name }}</span>
          </div>
        </label>
      </div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="reset" class="button button--delete" @click="showRemove" v-if="ceo.isAdded">Удалить
          </button>
          <button type="reset" class="button button--clear" @click="close">отменить
          </button>
          <button type="submit" class="button" :key="dbl" @click="save">СОХРАНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="del-document-form">
    <div class="popup">
      <div class="modal-title">Удаление записи</div>
      <div class="modal-body">Вы действительно хотите удалить данные о директоре РФРП?</div>
      <div class="modal-bottom button-dialog-center">
        <div class="buttons-block">
          <button type="button" class="button button--clear" @click="close">Закрыть
          </button>
          <button type="submit" class="button button--delete" @click="remove">Удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" id="close-document">
    <div class="popup">
      <div class="modal-title">Данные удалены</div>
      <div class="modal-body"></div>
      <div class="modal-bottom">
        <div class="buttons-block">
          <button type="button" class="button" @click="close">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>