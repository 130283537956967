import JwtService from "@/common/jwt.service";
import {API_URL, GATEWAY_URL} from "@/common/config";
import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = API_URL;

const ApiService = {
    setHeader() {
        if (JwtService.getToken() !== "" && JwtService.getToken() !== null) {
            axios.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${JwtService.getToken()}`;
        } else {
            delete axios.defaults.headers.common.Authorization
        }
    },

    async query(resource, params) {
        this.setHeader();

        return axios.get(resource, params).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },
    
    get(resource, slug = "") {
        this.setHeader();
        
        return axios.get(`${resource}${slug}/`)
    },
    
    getBlob(resource, slug = "") {
        this.setHeader();
        
        return axios.get(`${resource}${slug}/`, { responseType: "blob" })
    },

    get2(resource, slug = "") {
        this.setHeader();

        return axios.get(`${resource}${slug}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    post(resource, params) {
        this.setHeader();

        return axios.post(`${resource}`, params);
    },

    postEncodedURI(resource, params) {
        return axios.post(`${resource}`, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            baseURL: ""
        });
    },

    postForm(resource, form) {
        this.setHeader();

        return axios.postForm(`${resource}`, form);
    },

    postEncoded(resource, form) {
        this.setHeader();

        return axios.post(`${resource}`, form, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    },

    update(resource, slug, params) {
        this.setHeader();

        return axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        this.setHeader();

        return axios.put(`${resource}`, params);
    },

    delete(resource) {
        this.setHeader();

        return axios.delete(resource);
    }
};
export const Regions = {
    async list(params) {
        return ApiService.query("/regions", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async item(id) {
        return ApiService.get(`/regions/${id}`);
    },
    async permissions() {
        return ApiService.get(`/permissions`);
    },
    async statistics(id) {
        return ApiService.get(`/regions/${id}/statistics`);
    },
    async responsibilities(id) {
        return ApiService.get(`/regions/${id}/responsibilities`);
    },
    async projectManagers() {
        return ApiService.get(`/regions/project-managers`);
    },
    async regionalExperts() {
        return ApiService.get(`/regions/regional-experts`);
    },
    async updateResponsibilities(id, params) {
        return ApiService.put(`/regions/${id}/responsibilities`, params);
    },
    async documents(id) {
        return ApiService.get2(`/regions/${id}/documents`);
    },
    async districts() {
        return ApiService.get2(`/districts`);
    },
    async npaStatistics(id) {
        return ApiService.get2(`/regions/${id}/npa/statistics`);
    },
    async npaRegistry(id) {
        return ApiService.get2(`/regions/npa?regionId=${id}`);
    },
    async contacts(id) {
        return ApiService.get2(`/regions/${id}/contacts`);
    },
    async createContact(id, params) {
        return ApiService.post(`/regions/${id}/contacts`, params);
    },
    async updateContact(id, contactID, params) {
        return ApiService.put(`/regions/${id}/contacts/${contactID}`, params);
    },
    async removeContact(id, contactID) {
        return ApiService.delete(`/regions/${id}/contacts/${contactID}`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/regions/${id}/documents`, params);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/regions/${id}/documents/${docID}`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/regions/${id}/documents/${docID}`);
    },
}
export const Funds = {
    async item(id) {
        return ApiService.get(`/funds/${id}`);
    },
    async updateFunds(id, params) {
        return ApiService.put(`/funds/${id}`, params);
    },
    async contacts(id) {
        return ApiService.get(`/funds/${id}/contacts`);
    },
    async capitalization(id) {
        return ApiService.get(`/funds/${id}/asset`);
    },
    async capitalizationStatistics(id) {
        return ApiService.get(`/funds/${id}/asset/statistics`);
    },
    async collabPrograms(id) {
        return ApiService.get(`/funds/${id}/collab-programs`);
    },
    async createCollabProgram(id, params) {
        return ApiService.post(`/funds/${id}/collab-programs`, params);
    },
    async updateCollabProgram(id, pid, params) {
        return ApiService.put(`/funds/${id}/collab-programs/${pid}`, params);
    },
    async removeCollabProgram(id, pid) {
        return ApiService.delete(`/funds/${id}/collab-programs/${pid}`);
    },
    async ownPrograms(id) {
        return ApiService.get(`/funds/${id}/own-programs`);
    },
    async createOwnPrograms(id, params) {
        return ApiService.post(`/funds/${id}/own-programs`, params);
    },
    async updateOwnPrograms(id, opID, params) {
        return ApiService.put(`/funds/${id}/own-programs/${opID}`, params);
    },
    async removeOwnPrograms(id, opID) {
        return ApiService.delete(`/funds/${id}/own-programs/${opID}`);
    },
    async ownProgramsAgreements(id) {
        return ApiService.get(`/funds/${id}/own-programs/agreements`);
    },
    async ownProgramsCreateAgreement(id, params) {
        return ApiService.post(`/own-programs/${id}/agreements`, params);
    },
    async ownProgramsUpdateAgreement(id, agreement, params) {
        return ApiService.put(`/own-programs/${id}/agreements/${agreement}`, params);
    },
    async ownProgramsDeleteAgreement(id, agreement) {
        return ApiService.delete(`/own-programs/${id}/agreements/${agreement}`);
    },
    async ownProgramAgreement(program, id) {
        return ApiService.get(`/own-programs/${program}/agreements/${id}`);
    },
    async documents(id) {
        return ApiService.get(`/funds/${id}/documents`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/funds/${id}/documents`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/funds/${id}/documents/${docID}`);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/funds/${id}/documents/${docID}`, params);
    },
    async agreementStatistics(id) {
        return ApiService.get(`/funds/${id}/own-programs/agreements/statistics`);
    },
    async agreementRegistry(params) {
        return ApiService.query(`/own-programs/agreements/registry`, {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async agreementFundList(id) {
        return ApiService.get(`/funds/own-programs/agreements/${id}/list`);
    },
    async createCEO(id, params) {
        return ApiService.put(`/funds/${id}/ceo`, params);
    },
    async deleteCEO(id) {
        return ApiService.delete(`/funds/${id}/ceo`);
    },
    async createCEOPhoto(id, params) {
        return ApiService.put(`/funds/${id}/ceo/photo`, params);
    },
    async deleteCEOPhoto(id) {
        return ApiService.delete(`/funds/${id}/ceo/photo`);
    },
}
export const Rgo = {
    async item(id) {
        return ApiService.get(`/regions/${id}/rgo`);
    },
    async update(id, rgoID, params) {
        return ApiService.put(`/regions/${id}/rgo/${rgoID}`, params);
    },
    async create(id, params) {
        return ApiService.post(`/regions/${id}/rgo`, params);
    },
    async counterparties(text) {
        return ApiService.query(`/counterparties/search`, {
            params: {
                text: text,
                charsCountActivation: 2
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    async documents(id) {
        return ApiService.get2(`/rgo/${id}/documents`);
    },
    async createDocument(id, params) {
        return ApiService.post(`/rgo/${id}/documents`, params);
    },
    async removeDocument(id, docID) {
        return ApiService.delete(`/rgo/${id}/documents/${docID}`);
    },
    async updateDocument(id, docID, params) {
        return ApiService.put(`/rgo/${id}/documents/${docID}`, params);
    },
    async updateSigningState(id, rgoId, params) {
        return ApiService.put(`/rgo/${rgoId}/signing-state`, params);
    },
}
export const Dictionary = {
    async legalForms() {
        return ApiService.get2(`/dictionaries/legal-forms`);
    },
    async collabPrograms() {
        return ApiService.get2(`/dictionaries/collab-programs`);
    },
    async supportedIndustries() {
        return ApiService.get2(`/dictionaries/supported-industries`);
    },
    async targetedUses() {
        return ApiService.get2(`/dictionaries/targeted-uses`);
    },
    async regulationsStatuses() {
        return ApiService.get2(`/dictionaries/regulations-statuses`);
    },
    async regulationsTypes() {
        return ApiService.get2(`/dictionaries/regulations-types`);
    },
    async fundCapitalizationYears() {
        return ApiService.get2(`/dictionaries/fund-capitalization-years`);
    },
    async rgoAccreditations() {
        return ApiService.get2(`/dictionaries/rgo-accreditations`);
    },
    async rgoAgreementStatuses() {
        return ApiService.get2(`/dictionaries/rgo-agreement-statuses`);
    },
    async rgoForm2021Statuses() {
        return ApiService.get2(`/dictionaries/rgo-form2021-statuses`);
    },
    async rgoGuaranteeAmountsYears() {
        return ApiService.get2(`/dictionaries/rgo-guarantee-amounts-years`);
    },
    async loanTargets() {
        return ApiService.get2(`/dictionaries/loan-targets`);
    },
    async regionContactTypes() {
        return ApiService.get2(`/dictionaries/region-contact-types`);
    },
}
export const FileDocument = {
    async fileTemp(form) {
        return ApiService.postForm(`/filestorage/file/temp`, form);
    },
    async fileData(id) {
        return ApiService.getBlob(`/filestorage/file/data/${id}`);
    },
    async fileMeta(id) {
        return ApiService.get(`/filestorage/file/meta/${id}`);
    },
    async fileDataBlob(id) {
        return ApiService.getBlob(`/filestorage/file/data/${id}`);
    },
}

export const Regulations = {
    async listNpa() {
        return ApiService.get(`/regions/npa/registry`);
    },
    async listNpaQuery(regionId) {
        return ApiService.get2(`/regions/npa?regionId=${regionId}`);
    },
    async getNpa(regionId, regulationId) {
        return ApiService.get(`/regions/${regionId}/npa/${regulationId}`);
    },
    async createNpa(regionId, npaData) {
        return ApiService.post(`/regions/${regionId}/npa`, npaData);
    },
    async updateNpa(regionId, regulationId, npaData) {
        return ApiService.post(`/regions/${regionId}/npa/${regulationId}`, npaData);
    },
    async removeNpa(regionId, regulationId) {
        return ApiService.delete(`/regions/${regionId}/npa/${regulationId}`);
    }
}
export const Capitalization = {
    async CapitalizationData(fundId) {
        return ApiService.get(`/funds/capitalization-and-budget/${fundId}`);
    },
    async FilteredCapitalizationData(fundId, year) {
        return ApiService.get(`/funds/${fundId}/asset/${year}`);
    },
    async updateCapitalization(fundId, params) {
        return ApiService.post(`/funds/${fundId}/asset/capitalization`, params);
    },
    async updateBudget(fundId, params) {
        return ApiService.post(`/funds/${fundId}/asset/budget`, params);
    },
    async statisticsCapitalization(fundId) {
        return ApiService.get(`/funds/${fundId}/asset/statistics`);
    },
    async createDocument(id, fundId, year, quarter, params) {
        return ApiService.post(`/funds/${fundId}/asset/report/${year}/${quarter}`, params);
    },
    async quarterDocuments(fundId, year, quarter) {
        return ApiService.get(`/funds/${fundId}/asset/report/${year}/${quarter}`);
    },
}
export const MySelf = {
    async card() {
        return ApiService.get(`/me`);
    },
}
export const Gateway = {
    async get(resource, slug = "") {
        return axios.get(`${resource}${slug}`, {
            baseURL: GATEWAY_URL,
            headers: {
                Authorization: `Bearer ${JwtService.getToken()}`
            }
        })
    },
    async Counterparty(counterpartyId) {
        return Gateway.get(`/counterparty/?query=${counterpartyId}`);
    },
}

export default ApiService;
